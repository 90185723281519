import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Bars3Icon, XMarkIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import logo from '../assets/Img/logo.jpeg';

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const closeMenu = () => setMenuOpen(false);

  return (
    <div className="min-h-screen flex flex-col bg-black relative">
      <header className="sticky top-0 bg-gray-800 bg-opacity-30 text-white p-4 flex justify-between items-center z-50">
        <Link to="/" className="flex items-center" onClick={closeMenu}>
          <img src={logo} alt="Logo" className="h-8 w-8 mr-2" />
          <div>
            <h1 className="text-lg font-bold md:text-base sm:text-sm">Beach Comber Travel Consult</h1>
          </div>
        </Link>
        <nav className="hidden md:flex space-x-4 flex-1 justify-center">
          <Link to="/services" className="hover:underline">Services</Link>
          <Link to="/contact" className="hover:underline">Contact</Link>
          <Link to="/blog" className="hover:underline">Blog</Link>
        </nav>
        <div className="hidden md:flex space-x-4 items-center">
          <FaFacebook src="https://web.facebook.com/profile.php?id=61564427541930" className="h-6 w-6" />
          <FaTwitter className="h-6 w-6" />
          <FaInstagram className="h-6 w-6" />
          <UserCircleIcon className="h-6 w-6" />
        </div>
        <div className="md:hidden flex items-center">
          <UserCircleIcon className="h-6 w-6 mr-4" />
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            {menuOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
          </button>
        </div>
      </header>
      {menuOpen && (
        <nav className="md:hidden bg-gray-800 bg-opacity-30 text-white p-4 flex flex-col space-y-4 z-40">
          <Link to="/services" className="hover:underline" onClick={closeMenu}>Services</Link>
          <Link to="/contact" className="hover:underline" onClick={closeMenu}>Contact</Link>
          <Link to="/blog" className="hover:underline" onClick={closeMenu}>Blog</Link>
          <div className="flex space-x-4 mt-4">
            <FaFacebook className="h-6 w-6" />
            <FaTwitter className="h-6 w-6" />
            <FaInstagram className="h-6 w-6" />
          </div>
        </nav>
      )}
      <main className="flex-grow w-full">{children}</main>
      <footer className="bg-gray-800 bg-opacity-30 text-white p-4 text-center">
        © 2024 Beach Comber Travel Consult
      </footer>
    </div>
  );
};

export default Layout;
